// Generated by Framer (0b5b7f5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["lNvFJ7hzy", "dFfTTzySZ", "mV9RSl4bP", "oz3RgVv2K"];

const serializationHash = "framer-68GUz"

const variantClassNames = {dFfTTzySZ: "framer-v-159rtfl", lNvFJ7hzy: "framer-v-t7f1on", mV9RSl4bP: "framer-v-mn83wk", oz3RgVv2K: "framer-v-wzeek7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "dFfTTzySZ", Leptop: "lNvFJ7hzy", Phone: "oz3RgVv2K", Tablet: "mV9RSl4bP"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "lNvFJ7hzy"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "lNvFJ7hzy", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-t7f1on", className, classNames)} data-framer-name={"Leptop"} layoutDependency={layoutDependency} layoutId={"lNvFJ7hzy"} ref={ref ?? ref1} style={{backgroundColor: "rgb(68, 204, 255)", ...style}} {...addPropertyOverrides({dFfTTzySZ: {"data-framer-name": "Desktop"}, mV9RSl4bP: {"data-framer-name": "Tablet"}, oz3RgVv2K: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-68GUz.framer-g653is, .framer-68GUz .framer-g653is { display: block; }", ".framer-68GUz.framer-t7f1on { height: 139px; overflow: hidden; position: relative; width: 738px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 139
 * @framerIntrinsicWidth 738
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"dFfTTzySZ":{"layout":["fixed","fixed"]},"mV9RSl4bP":{"layout":["fixed","fixed"]},"oz3RgVv2K":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertP8d51AV8: React.ComponentType<Props> = withCSS(Component, css, "framer-68GUz") as typeof Component;
export default FramertP8d51AV8;

FramertP8d51AV8.displayName = "Footer";

FramertP8d51AV8.defaultProps = {height: 139, width: 738};

addPropertyControls(FramertP8d51AV8, {variant: {options: ["lNvFJ7hzy", "dFfTTzySZ", "mV9RSl4bP", "oz3RgVv2K"], optionTitles: ["Leptop", "Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramertP8d51AV8, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})